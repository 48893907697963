// router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/HomePage.vue'; // 將 Home 作為首頁組件
import SEOTool from '@/SEOTool.vue'; // 引入新頁面組件
import CozePage from '@/CozePage.vue'; // 引入新頁面組件
import MasterPage from '@/MasterPage.vue'; // 引入新頁面組件
import './../firebaseConfig'; // 引入 Firebase 初始化

// 動態加載 CSS 文件的輔助函數
const loadCSS = (href) => {
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = href;
  link.className = 'dynamic-css'; // 給 CSS 加載一個標籤
  document.head.appendChild(link);
};

const removeCSS = () => {
  const links = document.querySelectorAll('.dynamic-css');
  links.forEach(link => link.remove()); // 刪除所有動態加載的 CSS
};

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage, // 將 Home 設定為首頁組件
    beforeEnter: () => {
      removeCSS();
      loadCSS('/css/main.css'); // 根據路由加載對應的 CSS
    },
  },
  {
    path: '/seo', // 新頁面的路徑
    name: 'SEOTool',
    component: SEOTool, // 新頁面組件
    beforeEnter: () => {
      removeCSS();
      loadCSS('/css/main.css'); // 根據路由加載對應的 CSS
    },
  },
  {
    path: '/coze', // 新頁面的路徑
    name: 'CozePage',
    component: CozePage, // 新頁面組件
    beforeEnter: () => {
      removeCSS();
      loadCSS('/css/coze.css'); // 根據路由加載對應的 CSS
    },
  },
  {
    path: '/master', // 新頁面的路徑
    name: 'MasterPage',
    component: MasterPage, // 新頁面組件
    beforeEnter: () => {
      removeCSS();
      loadCSS('/css/master.css'); // 根據路由加載對應的 CSS
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
