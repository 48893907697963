<template>
  <div class="containerx">
   <!-- 登入提示框 -->
   <div v-if="!user" class="login-overlay">
      <div class="login-box">
        <img src="/img/ai-sandy-logo.png" style="border-radius: 50%;" width="100">
        <h2>AI-Sandy</h2>
        <p></p>
        <button class="gsi-material-button" @click="googleSignIn">
            <div class="gsi-material-button-state"></div>
            <div class="gsi-material-button-content-wrapper">
              <div class="gsi-material-button-icon">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" xmlns:xlink="http://www.w3.org/1999/xlink" style="display: block;">
                  <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                  <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                  <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                  <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                  <path fill="none" d="M0 0h48v48H0z"></path>
                </svg>
              </div>
              <span class="gsi-material-button-contents">Sign in with Google</span>
              <span style="display: none;">Sign in with Google</span>
            </div>
          </button>
          <div class="login-footer">
            <p class="text-capitalize">AI 互學、交流、討論才有趣</p>
          </div>
      </div>
    </div>
    <!-- loading-->
    <div v-if="showTestAnimation" class="test-loading-overlay">
      <div><img src="/img/reading.png" width="200px"></div>
      <div class="loading-animation"></div>&nbsp;&nbsp;<p class="fs-4">生成測驗題中</p>
    </div>
    <div ref="confettiContainer" class="confetti-container"></div>
    <audio ref="scoreAudio" src="/audio/you-win.mp3"></audio>
    
    <!-- 在適當的位置添加這段代碼 -->
    <div v-if="showScore" class="score-display fs-1">{{ scoreText }}</div>

    
    <div v-if="showAboutMe" class="aboutme-div">
      <button @click="closeAboutMe" class="close-button">X</button> 
      <h3 class="fs-3 ai-sandy">關於我們</h3>
      <img src="/img/ai-sandy-logo.png" width="150">
      <p></p>
      <p>我們致力於開發一個專為兒童設計的AI學習平台，旨在為孩子們提供一個互動、共享和成長的學習環境。在這個平台上，每個孩子不僅能夠學習新知識，還能了解其他小朋友正在學什麼、準備哪些測驗，從而促進相互間的學習和交流。</p>
      <p>我們相信，學習不應僅限於課堂內的單向傳授，而是一個涉及家長、孩子和老師共同參與的過程。因此，我們的平台旨在成為一個連接家庭和學校、促進三方溝通的有效工具，使教育更加透明和多元化。</p>
      <p>未來，我們將利用先進的AI技術，根據每位學生的學習進度和偏好，提供個性化的學習內容。我們的目標是實現因材施教，讓每個孩子都能在最適合自己的方式下學習和進步。</p>
      <p>我們誠邀各位家長、學生和教育工作者提供寶貴的建議和鼓勵。讓我們攜手合作，為孩子們創造一個充滿無限可能的學習世界。</p>
    </div>
    <!-- questions-div -->
    <div v-if="showTestQuestions" class="test-questions-div">
      <p class="fs-3 ai-sandy">== 看看可以拿幾分 ==</p>
      <button @click="closeTestQuestions" class="close-button">X</button> 
      <div v-for="(question, index) in testQuestions" :key="index" class="test-question">
          <h3>{{ question.question }}</h3>
          <ul>
              <li class="fw-medium fs-5 text" v-for="(optionText, optionKey) in question.options" :key="optionKey">
                  <input type="radio" :name="`question-${index}`" :value="optionKey" v-model="question.selectedOption">
                  {{ optionKey }}. {{ optionText }}
              </li>
          </ul>
          <div v-if="question.explanationVisible" class="explanation fs-4 --bs-warning-rgb">
              <p>解答：{{ question.explanation }}</p>
          </div>
      </div>
      <button @click="submitTest" v-if="showSubmitTestButton" class="btn btn-success">提交答案</button>
    </div>
    
    <!-- 導航欄 -->
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container-fluid">
        <!-- Logo 和產品名稱 -->
        <a class="navbar-brand" href="#">
          <img src="/img/ai-sandy-logo.png" style="border-radius: 50%;margin-right: 5px;" alt="" width="30" height="30" class="d-inline-block align-text-top">
          AI 佛經大師
        </a>
        
        <!-- 切換按鈕（小屏幕適配） -->
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        
        <!-- 選單項目 -->
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="#">首頁</a>
            </li>
            <!--
            <li class="nav-item">
              <a class="nav-link" @click="clickMenu" href="#">課程探索</a>
            </li>
            -->
            <li class="nav-item">
              <a class="nav-link" @click="clickAboutMe" href="#">關於我們</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" target="_blank" href="https://forms.gle/MT1VP2cyQuUMLnbc7">問題反饋</a>
            </li>
          </ul>
        </div>
        <!-- 選單項目 -->
      </div>
    </nav>
    <!-- 側邊欄切換按鈕 -->
    <button @click="toggleSidebar" :style="toggleButtonStyle" class="btn btn-light slidebar-btn"><i class="fa fa-book" aria-hidden="true"></i></button>
    
    <!-- Menu-->
    <div v-if="showMenus"  class="common-dialog ai-sandy">
      <p class="fs-3 ai-sandy">課程探索</p>
      <button @click="closeCourse" class="close-button">X</button> 
      <!-- 其他內容 -->
      <sub-menu :menus="menuTree"></sub-menu>
    </div>
    <!-- 側邊欄 -->
    <div v-if="isSidebarVisible" class="sidebar">
      <div v-for="record in sidebarRecords" :key="record.id" class="slidebar-record">
        <img :src="record.photo_url ? record.photo_url : '/img/logo.png'" alt="大頭照" class="slidebar-avatar">
        <div class="slidebar-content" @click="recordClicked(record,true)">
          <div>{{ record.question }}</div>
          <div class="date-text">{{ record.asked_at }}</div>
        </div>
      </div>
    </div>

    <!-- 主要內容，加入半透明背景控制 -->
    <div class="main-content" :class="{ 'blur': !user }">
      <div class="video-block">
        <div class="audio-css">
          <audio ref="audioPlayer" v-show="showAudio" controls></audio>
        </div>
        <div class="voice-controls-div">
          <button class="btn btn-light small-circle-button" @click="stopVoice">
              <i :class="['fas', isMuted ? 'fa-volume-mute' : 'fa-volume-up']"></i>
          </button>
        </div>
        <video :src="currentVideoSrc" width="80%" muted ref="videoPlayer" class="video-div"></video>
        <div>
          <select class="name-css" v-model="selectedName" @change="changeVideoSrc">
            <option v-for="name in names" :key="name" :value="name">{{ name }}</option>
          </select>
        </div>
        <div class="record-btn-div">
          <button :class="{'btn btn-danger circle-button': isRecognizing, 'btn btn-light circle-button': !isRecognizing}" :disabled="isSubmitting || isRecognizing" @click="startSpeechRecognition">&nbsp;<i class="fas fa-microphone"></i>&nbsp;</button>
        </div>
        <div class="controls-panel">
          <div class="controls">
            <!--<button class="btn btn-light" @click="focusInput"><i class="fas fa-keyboard"></i></button>-->
          </div>
        </div>
        <p class="text-light">{{ submitButtonText }}</p>
        <div class="input-area-container">
          <textarea v-model="inputText" ref="textInput" class="form-control text-input-area" placeholder="告訴我你的問題..." @input="adjustHeight"></textarea>
          <div class="button-container">
            <button @click="handleSubmit" :disabled="isSubmitting" class="btn btn-aisandy">
              <i class="fas fa-paper-plane"></i>
            </button>
          </div>
        </div>
        <p class="fw-lighter" style="color:#696969">AI 資訊可能會出錯。請考慮核對重要資訊。</p>
      </div>
      <div class="text-block" :style="{ 'max-height': textBlockMaxHeight }" ref="textBlock">
        <div v-for="(item, index) in contentItems" :key="index">
          <!-- 如果是標題，使用h3標籤 -->
          <h2 v-if="item.isHeader" class="fs-2 text-content">{{ item.value }}</h2>
          <!-- 如果不是標題且是文字類型且可點擊，使用div顯示並綁定點擊事件 -->
          <div v-else-if="item.type === 'text'" class="fs-3 text-content clickable-text" @click="() => handleKeywordClick(item.value)">
            {{ item.value }}
          </div>
          <!-- 圖片內容 -->
          <img v-if="item.type === 'image'" width="80%" :src="item.value" class="keyword-img" :alt="item.value" />
        </div>
        <!-- 顯示關鍵字的區塊 -->
        <div class="keywords-list" v-if="filteredKeywords && filteredKeywords.length > 0">
          <button 
            v-for="(keyword, index) in filteredKeywords" 
            :key="index" 
            @click.prevent="handleKeywordClick(keyword)" 
            class="btn btn-light keyword-button">
            {{ keyword }}
          </button>
        </div>
      </div>
      <button @click="createATest" class="btn btn-secondary float-test-button "><i class="fas fa-vial"></i><span>&nbsp;測驗看看</span></button>
      <button @click="toggleChatList" class="btn btn-secondary float-button"><i class="fas fa-history"></i><span>&nbsp;我的紀錄</span></button>
      <transition name="slide">
        <div v-if="showChatList" class="chat-list">
          <div v-for="record in chatRecords" :key="record.id" class="chat-item" @click="recordClicked(record,true)">
            {{ record.question }}
          </div>
        </div>
      </transition>
    </div>
    <div class="text-center footer-div">
      Sound Effect by <a href="https://pixabay.com/users/floraphonic-38928062/?utm_source=link-attribution&utm_medium=referral&utm_campaign=music&utm_content=183949">floraphonic</a> from <a href="https://pixabay.com/sound-effects//?utm_source=link-attribution&utm_medium=referral&utm_campaign=music&utm_content=183949">Pixabay</a>
      <p>©2024 AI-Sandy</p>
    </div>
  </div>
</template>
<script>
import { getAuth, signInWithPopup, GoogleAuthProvider,signInWithCustomToken } from 'firebase/auth';
import SubMenu from './SubMenu.vue'

export default {
  name: 'MasterPage',
  components: {
    SubMenu
  },
  data() {
    return {
      apikey:'',
      googleImageAPIKey:'',
      currentImageSearchAPI: 'bing', // 預設使用Bing，可選值為'bing'或'google'
      user: null, // 用來儲存用戶資訊
      isMuted: true, // 用於追蹤是否靜音的狀態
      selectedName: 'Onyx', // 選中的名字
      names: ['Onyx','Echo','Nova'], // 名字列表
      currentVideoSrc: 'onyx.mp4', // 當前視頻文件的路徑
      sidebarRecords: [],
      isSidebarVisible: false, // 新增，用於控制側邊欄顯示
      showChatList: false, // 控制聊天列表顯示
      chatRecords: [], // 存儲從API獲取的聊天紀錄
      chatListMaxHeight: '400px', // 聊天列表的初始最大高度
      textBlockMaxHeight: '900px', // 文字區塊的初始最大高度
      isSubmitting: false, // 控制按鈕是否處於提交狀態
      submitButtonText: '', // 控制按鈕文案
      isRecognizing: false, // 新增一個標誌，用於表示是否正在進行語音識別
      showAudio: false, // 初始時不顯示audio元素
      inputText: '', // 綁定輸入框的文字
      displayText: '', // 用於顯示的文字
      fullText: '這裡是將要顯示的完整文字。這段文字會像讀稿機一樣逐字顯示。',
      currentCharIndex: 0, // 當前顯示到的字符索引
      currentChatObj:{},
      currentChatKeywords:"",
      showTestAnimation: false,
      showTestQuestions: false,
      showConfetti: false, // 控制得分動畫顯示的標誌
      showScore: false, // 控制分數顯示的標誌
      scoreText: '', // 分數文本
      showSubmitTestButton: true, //提交問題按鈕狀態
      testQuestions: [],
      filteredKeywords:[],
      showAboutMe:false,
      menus: [], // 存儲從API獲取的選單數據
      showMenus: false, // 控制選單顯示的狀態
      // 其他數據...
      contentItems: [
      ],
    };
  },
  computed: {
    toggleButtonStyle() {
      // 根據側邊欄是否顯示來決定按鈕的樣式
      if (this.isSidebarVisible) {
        return {
          transform: 'translateX(300px)', // 側邊欄展開時，按鈕向右移動
        };
      } else {
        return {
          transform: 'translateX(0)', // 側邊欄收起時，按鈕回到原位
        };
      }
    },
    menuTree() {
      // 確保 menus 是陣列
      if (!Array.isArray(this.menus)) {
        console.warn('menus is not an array', this.menus);
        return [];
      }

      const map = {};
      const roots = [];
      this.menus.forEach(menu => {
        map[menu.id] = { ...menu, children: [] };
      });
      this.menus.forEach(menu => {
        if (menu.parent_id !== null) {
          if (map[menu.parent_id]) {
            map[menu.parent_id].children.push(map[menu.id]);
          }
        } else {
          roots.push(map[menu.id]);
        }
      });
      return roots;
    }
  },
  mounted() {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = './public/css/master.css';  // 替換為你想加載的 CSS 路徑
    document.head.appendChild(link);
    this.initializeMuteState();
    this.checkUserLoginStatus();
    this.typeText();
    //在組件掛載時計算並設定max-height
    this.calculateHeights();
    //fetch 最後一次的查詢結果
    this.initializeLastData();
    this.fetchMenus(); // 獲取選單數據
    window.addEventListener('resize', this.calculateHeights);
  },
  methods: {
    closeCourse(){
      this.showMenus = false;
    },
    clickMenu() {
      // 這裡可以添加顯示或隱藏選單的邏輯
      // 例如，切換一個布爾值來控制選單的顯示狀態
      this.showMenus = !this.showMenus;
    },
    async loginWithCustomToken() {
      const baseUrl = process.env.VUE_APP_API_BASE_URL; // 獲取環境變量中的API基礎URL
      const auth = getAuth();
      // 假設 `uid` 是你想要為用戶生成自定義令牌的唯一標識符
      const timestamp = new Date().getTime();
      console.log(timestamp.toString());
      const uid = this.user.uid;
      try {
        const response = await fetch(`${baseUrl}/auth/generateCustomToken`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ uid }),
        });
        if (!response.ok) throw new Error('Failed to retrieve custom token');
        const { customToken } = await response.json();
        await signInWithCustomToken(auth, customToken);
        console.log('User signed in with custom token');
        // 處理用戶成功登錄後的邏輯...
      } catch (error) {
        console.error('Error logging in with custom token:', error);
      }
    },
    submitTest() {
      // 檢查是否所有問題都已回答
      const isAllAnswered = this.testQuestions.every(question => question.selectedOption !== undefined);

      if (!isAllAnswered) {
        // 如果有未回答的問題，顯示提示並中止提交
        alert('請回答所有問題後再提交！');
        return; // 中止函數執行
      }
      let score = 0; // 初始化得分
      // 遍歷每個問題來檢查答案
      this.testQuestions.forEach(question => {
        // 確保答案為陣列格式
        let correctAnswers = Array.isArray(question.answer) ? question.answer : [question.answer];
        
        // 檢查選擇的選項是否包含在正確答案陣列中
        if (correctAnswers.includes(question.selectedOption)) {
          score++; // 答案正確，得分加一
          question.isCorrect = true; // 標記正確答案
        } else {
          question.isCorrect = false; // 標記錯誤答案
        }
        question.explanationVisible = true; // 顯示答案說明
      });

      // 計算總分
      const totalScore = score / this.testQuestions.length * 100;
      
      this.scoreText = `你的得分是：${totalScore}分`;
      this.showScore = true;
      this.showSubmitTestButton = false;
      if(totalScore>=50){
        // 播放得分音樂和動畫（假設已有相應的實現）
        this.playScoreMusicAndAnimation(true);
      }else{
        this.playScoreMusicAndAnimation(false);
      }

      // 更新顯示，以便顯示正確答案和答案說明
      this.showTestQuestions = false;
      setTimeout(() => {
        this.showTestQuestions = true;
      }, 100);
    },
    generateConfetti() {
      const confettiCount = 100; // 彩帶的數量
      const colors = ['#FFC300', '#FF5733', '#C70039', '#900C3F', '#581845']; // 彩帶顏色
      const confettiElements = [];

      for (let i = 0; i < confettiCount; i++) {
        const confetti = document.createElement('div');
        confetti.classList.add('confetti-piece');
        confetti.style.backgroundColor = colors[Math.floor(Math.random() * colors.length)];
        confetti.style.left = `${Math.random() * 100}%`;
        confetti.style.animationName = 'confetti';
        confetti.style.animationDuration = `${Math.random() * 2 + 1}s`; // 1到3秒的動畫持續時間
        confetti.style.animationDelay = `${Math.random()}s`; // 最多1秒的延遲
        confetti.style.animationIterationCount = 'infinite';
        confetti.style.transform = `rotate(${Math.random() * 360}deg)`;

        confettiElements.push(confetti);
      }

      return confettiElements;
    },
    clearConfetti() {
      const confettiContainer = this.$refs.confettiContainer;
      if (confettiContainer) {
        confettiContainer.innerHTML = ''; // 清空彩帶容器中的內容
        this.showConfetti = false; // 更新狀態為不顯示彩帶
      }
    },
    playScoreMusicAndAnimation(isWin) {
      // 清空現有的彩帶
      const confettiContainer = this.$refs.confettiContainer;
      confettiContainer.innerHTML = '';

      // 生成新的彩帶
      const confettiElements = this.generateConfetti();
      if(isWin){
            confettiElements.forEach(confetti => {
            confettiContainer.appendChild(confetti);
          });
          this.$refs.scoreAudio.src='/audio/you-win.mp3';
        }else{
          this.$refs.scoreAudio.src='/audio/sad.mp3';
        }
      
      // 確保audio元素存在
      if (this.$refs.scoreAudio) {
        // 直接播放音頻，無需設置oncanplay
        
        this.$refs.scoreAudio.play().then(() => {
          // 音頻播放成功時的處理
        }).catch(error => {
          // 音頻播放失敗時的處理
          console.error("playScoreMusicAndAnimation 播放音頻失敗:", error);
        });

        // 監聽音頻播放完畢事件
        this.$refs.scoreAudio.onended = () => {
          this.clearConfetti();
          this.showScore = false;
        };
      } else {
        console.error("playScoreMusicAndAnimation 未找到audio元素");
      }
    },
    closeTestQuestions() {
      this.clearConfetti();
      this.showScore = false;
      this.showTestQuestions = false; // 隱藏題目區塊
    },
    async initializeLastData(){
      console.log('===initializeLastData');
      this.fetchLastData();
    },
    async clickAboutMe() {
      this.showAboutMe = true;
    },
    async closeAboutMe(){
      this.showAboutMe = false;
    },
    async createATest() {
      const baseUrl = process.env.VUE_APP_API_BASE_URL; // 獲取環境變量中的API基礎URL
      if(this.showTestQuestions==true){
        this.showTestQuestions = false;
      }
      this.showTestAnimation = true; // 顯示動畫
      try {
        const response = await fetch(`${baseUrl}/api/chatgpt/question`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            text: this.currentChatObj.answer,
            key: this.apikey,
            chatlog_id:this.currentChatObj.id
          })
        });

        if (response.ok) {
          this.showSubmitTestButton = true; //顯示提交答案按鈕
          const data = await response.json();
          console.log("===response.ok===");

          // 初始化一個空陣列來存儲問題
          let questions = [];

          // 檢查是否存在data.questions
          if (data.questions) {
            // 如果data.questions是一個陣列，直接賦值
            if (Array.isArray(data.questions)) {
              questions = data.questions;
            } else {
              // 如果不是陣列，將其包裝成陣列
              questions.push(data.questions);
            }
          } else if (data.question) {
            // 如果是單一問題格式
            questions.push(data);
          } else {
            // 遇到回覆question1, question2等格式
            for (let key in data) {
              if (key.startsWith('question')) {
                questions.push(data[key]);
              }
            }
          }

          // 將問題陣列賦值給this.testQuestions
          this.testQuestions = questions;
          
          setTimeout(() => {
            this.showTestQuestions = true; // 顯示測驗題目
            this.showTestAnimation = false; // 隱藏動畫
          }, 500); // 延遲500毫秒顯示測驗題目
        } else {
          this.showTestAnimation = false;
          this.showTestQuestions = true;
          this.testQuestions = null;

          console.error('Failed to fetch test questions');
        }
      } catch (error) {
        console.error('Error fetching test questions:', error);
      }
    },
    async fetchMenus() {
      const baseUrl = process.env.VUE_APP_API_BASE_URL; // 確保你在.env檔案中定義了VUE_APP_API_BASE_URL
      try {
        const response = await fetch(`${baseUrl}/menu/api/menus`);
        if (!response.ok) throw new Error('Failed to fetch menus');
        const data = await response.json();
        console.log(data);
        this.menus = Array.isArray(data) ? data : [];
      } catch (error) {
        console.error('Error fetching menus:', error);
        this.menus = []; // 發生錯誤時，也確保 menus 是一個空陣列
      }
    },
    async defaultValue(){
      this.contentItems = []; //清除資料
      this.contentItems.push({ type: 'text', value: '\n' });
      this.contentItems.push({ type: 'text', value: '什麼是 AI Sandy?', isHeader: true });
      this.contentItems.push({ type: 'text', value: '\n' });
      this.contentItems.push({ type: 'text', value: '我們致力於開發一個專為兒童設計的AI學習平台，旨在為孩子們提供一個互動、共享和成長的學習環境。在這個平台上，每個孩子不僅能夠學習新知識，還能了解其他小朋友正在學什麼、準備哪些測驗，從而促進相互間的學習和交流。\n我們相信，學習不應僅限於課堂內的單向傳授，而是一個涉及家長、孩子和老師共同參與的過程。因此，我們的平台旨在成為一個連接家庭和學校、促進三方溝通的有效工具，使教育更加透明和多元化。\n未來，我們將利用先進的AI技術，根據每位學生的學習進度和偏好，提供個性化的學習內容。我們的目標是實現因材施教，讓每個孩子都能在最適合自己的方式下學習和進步。\n我們誠邀各位家長、學生和教育工作者提供寶貴的建議和鼓勵。讓我們攜手合作，為孩子們創造一個充滿無限可能的學習世界。' });
      this.contentItems.push({ type: 'text', value: '\n' });
    },
    async fetchLastData() {
      // 檢查 this.user 是否存在且不為 null
      if (!this.user) {
        console.log('用戶未登入或用戶資訊未初始化');
        return; // 直接返回，不進行後續操作
      }
      const accountId = this.user.uid; // 替換成實際的帳號ID
      const baseUrl = process.env.VUE_APP_API_BASE_URL; // 獲取環境變量中的API基礎URL

        try {
          const response = await fetch(`${baseUrl}/api/last/query/${accountId}`);
          if (response.ok) {
            const record_resp = await response.json();
            this.recordClicked(record_resp,false);
          } else {
            //this.$toast.error('目前獲取聊天紀錄失敗');
            console.error('獲取 last 紀錄失敗');
          }
        } catch (error) {
          //this.$toast.error('目前獲取聊天紀錄失敗');
          this.defaultValue();
          console.error('獲取 last 紀錄時發生錯誤', error);
        }
      },
      initializeMuteState() {
        const voiceState = localStorage.getItem('voice');
        this.isMuted = voiceState === 'mute';
      },
      changeVideoSrc() {
      // 這裡是一個範例，實際邏輯可能需要根據你的需求進行調整
      switch (this.selectedName) {
          case 'Nova':
            this.currentVideoSrc = 'nova.mp4';
            break;
          case 'Echo':
            this.currentVideoSrc = 'echo.mp4';
            break;
          case 'Onyx':
            this.currentVideoSrc = 'onyx.mp4';
            break;  
          default:
            this.currentVideoSrc = 'onyx.mp4';
        }
      },
      toggleSidebar() {
        this.isSidebarVisible = !this.isSidebarVisible;
        if (this.isSidebarVisible) {
          this.fetchAllRecords();
        }
      },
      async fetchAllRecords() {
        const baseUrl = process.env.VUE_APP_API_BASE_URL; // 獲取環境變量中的API基礎URL
        try {
          const response = await fetch(`${baseUrl}/api/find`);
          if (response.ok) {
            const record_resp = await response.json();
            this.sidebarRecords = record_resp
          } else {
            //this.$toast.error('目前獲取聊天紀錄失敗');
            console.error('獲取All紀錄失敗');
          }
        } catch (error) {
          //this.$toast.error('目前獲取聊天紀錄失敗');
          console.error('獲取All紀錄時發生錯誤', error);
        }
      },
      checkUserLoginStatus() {
        const user = localStorage.getItem('user');
        if (user) {
          this.user = JSON.parse(user);
          // 進行其他需要的操作，例如隱藏登入框等
        }
      },
      googleSignIn() {
        const provider = new GoogleAuthProvider();
        const auth = getAuth();
        signInWithPopup(auth, provider)
          .then((result) => {
            // 登入成功，可以獲取用戶資訊
            this.user = result.user;
            localStorage.setItem('user', JSON.stringify(result.user));
            this.checkUser();
            //塞入預設值
            this.defaultValue();
            // 隱藏登入提示框
            this.fetchLastData(); // 使用 await 確保 fetchLastData 執行完畢
          })
          .catch((error) => {
            // 處理錯誤
            console.error(error);
          });
      },
      recordClicked(record,isPlayNow) {
        this.showChatList = false;
        this.isSidebarVisible = false;
        this.showTestAnimation = false;
        this.showTestQuestions = false;
        this.testQuestions = null;
        //以上先把測驗畫面關掉
        this.currentChatObj = record;
        // 將紀錄的詳細內容顯示到頁面上
        if(record.voice!==null){
          this.selectedName = record.voice.charAt(0).toUpperCase()+ record.voice.slice(1).toLowerCase();
        }else{
          this.selectedName = 'Onyx';
        }
        this.changeVideoSrc(this.selectedName);
        this.inputText = record.question; // 假設你有一個綁定到輸入框的數據屬性`inputText`
        this.displayText = record.answer; // 假設你有一個顯示ChatGPT回應的數據屬性`displayText`
        this.processResponse(record.question,record.answer,record,false);
        // 播放音檔
        if (record.audio_filename) {
          const audioUrl = record.audio_filename; // 請根據實際情況替換'你的音檔基礎URL'
          if(isPlayNow){
            this.playAudio(audioUrl);
          }
        }

        // 假設keywordResponse返回的keywords是一個包含關鍵字的數組
        if(record.keyword!==null){
          const keywords = record.keyword.split('/'); // 根據實際格式調整
            
          this.filteredKeywords = keywords
            // 過濾掉開頭為數字加點的行
            .filter(line => !/^\d+\.\s+/.test(line))
            // 過濾掉空行
            .filter(line => line.trim() !== '')
            // 新增：過濾掉包含換行符的行
            .filter(line => !/[\r\n]+/.test(line))
            // 新增：過濾掉包含特定符號的行
            .filter(line => !/[。,，;；:：!！?？]/.test(line))
            // 使用map和replace移除每行中的換行符和特定符號
            .map(line => line.replace(/[\r\n。,，;；:：!！?？]+/g, ''));// 使用map和replace移除每行中的換行符
            
        }else{
          this.filteredKeywords = [];
        }
      },
      stopVoice() {
        this.isMuted = !this.isMuted;
        localStorage.setItem('voice', this.isMuted ? 'mute' : 'unmute');
        this.muteAudioVideo();
      },
      muteAudioVideo() {
        if (this.isMuted) {
          console.log('mute===');
          // 如果靜音，暫停所有音頻和視頻播放
          const playPromise = this.$refs.videoPlayer.play();
          if (playPromise !== undefined) {
                // 播放開始後，根據靜音狀態控制
                if (this.isMuted) {
                  this.$refs.videoPlayer.pause();
                  this.$refs.audioPlayer.pause();
                }
            }
        } else {
          console.log('unmute===');
          // 如果取消靜音，根據需要恢復播放
          // 確保音頻可以播放時再執行play
          this.$refs.audioPlayer.oncanplay = () => {
          if (this.$refs.audioPlayer) {
              this.$refs.audioPlayer.play().then(() => {
                // 媒體播放開始後的操作
              }).catch(error => {
                console.error("muteAudioVideo 播放音頻時發生錯誤", error);
              });
              this.$refs.videoPlayer.muted = true;
              this.$refs.videoPlayer.loop = true; // 設置循環播放
              this.$refs.videoPlayer.play().then(() => {
                // 媒體播放開始後的操作
              }).catch(error => {
                console.error("muteAudioVideo 播放video時發生錯誤:", error);
              });
          }
        }
          // 如果有其他音頻播放需求，可以在這裡添加
        }
      },
      playAudio(audioUrl) {
            // 如果已經靜音，則不執行播放
            if (this.isMuted) return;
            this.showAudio = true; // 收到響應後顯示audio元素
            // 回應結果後按鈕恢復正常
            this.isSubmitting = false;
            this.submitButtonText = '';
            this.$refs.audioPlayer.src = `/answer_audio/${audioUrl}`; // 設置audio元素的src為audioUrl
            // 確保音頻可以播放時再執行play
            const voiceState = localStorage.getItem('voice');
            this.isMuted = voiceState === 'mute';
            this.muteAudioVideo();

            // 添加ended事件監聽器
            this.$refs.audioPlayer.onended = () => {
              this.showAudio = true; // 收到響應後顯示audio元素
              console.log('音頻播放完畢，停止視頻播放');
              this.$refs.videoPlayer.pause(); // 停止視頻播放
              this.$refs.videoPlayer.currentTime = 0; // 將視頻播放進度重置為開始
            };
      },
      toggleChatList() {
        this.showChatList = !this.showChatList;
        // 無論聊天列表的當前狀態如何，只要打開就重新載入數據
        if (this.showChatList) {
          this.fetchChatRecords();
        }
      },
      async fetchChatRecords() {
        const baseUrl = process.env.VUE_APP_API_BASE_URL; // 獲取環境變量中的API基礎URL
        const accountId = this.user.uid; // 替換成實際的帳號ID
        try {
          const response = await fetch(`${baseUrl}/api/query/${accountId}`);
          if (response.ok) {
            const record_resp = await response.json();
            this.chatRecords = record_resp
          } else {
            //this.$toast.error('目前獲取聊天紀錄失敗');
            console.error('獲取聊天紀錄失敗');
          }
        } catch (error) {
          //this.$toast.error('目前獲取聊天紀錄失敗');
          console.error('獲取聊天紀錄時發生錯誤', error);
        }
      },
      calculateHeights() {
        // 假設我們想要`.text-block`的max-height為視窗高度的50%
        this.textBlockMaxHeight = `${window.innerHeight * 0.9}px`;
        this.chatListMaxHeight = `${window.innerHeight * 0.5}px`;

      },
      displayTextByChar(text, index = 0) {
      if (index < text.length) {
        this.displayText += text.charAt(index); // 逐字添加到displayText
        setTimeout(() => {
          this.displayTextByChar(text, index + 1); // 遞迴調用自身，進行下一個字符的顯示
        }, 1000); // 這裡的100毫秒是逐字顯示的間隔，可以根據需要調整
      }
    },
    typeText() {
      if (this.currentCharIndex < this.fullText.length) {
        this.displayText += this.fullText.charAt(this.currentCharIndex);
        this.currentCharIndex++;
        // 設定文字顯示的速度，100ms顯示一個字符
        setTimeout(this.typeText, 100);
      }
    },
    focusInput() {
      // 當點擊鍵盤按鈕時，讓輸入框獲得焦點
      this.$refs.textInput.focus();
    },
    adjustHeight(event) {
      const textarea = event.target;
      textarea.style.height = 'auto'; // 先設定高度為auto以獲得正確的高度
      textarea.style.height = textarea.scrollHeight + 'px'; // 然後設定為scrollHeight以適應內容
    },
    startSpeechRecognition() {
      //開始停止聲音播放
      this.stopVoice();
       // 停止視頻播放
      if (this.$refs.videoPlayer) {
        this.$refs.videoPlayer.pause(); // 停止視頻播放
        this.$refs.videoPlayer.currentTime = 0; // 選擇性地重置視頻時間到開始
      }
 
      
      this.inputText = '';
      this.isRecognizing = true; // 開始識別時，將標誌設為true
      // 獲得焦點
      this.focusInput();
      // 使用Web Speech API實現語音識別
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      if (SpeechRecognition) {
        const recognition = new SpeechRecognition();
        recognition.lang = 'zh-TW'; // 設置語言
        recognition.start(); // 開始識別

        recognition.onresult = (event) => {
          const transcript = event.results[0][0].transcript; // 獲取識別結果
          this.inputText += transcript; // 將識別結果添加到輸入框
          // 保持焦點
          this.focusInput();
        };

        recognition.onerror = (event) => {
          console.error("語音識別錯誤:", event.error);
          this.isRecognizing = false; // 出現錯誤時，也將標誌設為false
        };

        // 監聽語音識別結束
        recognition.onend = () => {
          console.log("語音識別結束");
          // 語音識別結束後立即提交
          this.isRecognizing = false; // 識別結束時，將標誌設為false
          this.handleSubmit();
        };

      } else {
        alert('您的瀏覽器不支持語音識別功能。');
        this.isRecognizing = false; // 識別結束時，將標誌設為false
      }
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const textBlock = this.$refs.textBlock;
        textBlock.scrollTop = textBlock.scrollHeight;
      });
    },
    async fetchImagesFromBing(keyword) {
      const baseUrl = process.env.VUE_APP_API_BASE_URL; // 獲取環境變量中的API基礎URL
      const response = await fetch(`${baseUrl}/api/search/images?keyword=${encodeURIComponent(keyword)}`, {
        method: 'GET',
        // 根據API要求可能需要添加查詢參數
      });
      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }
      const data = await response.json();
      // 解析回傳的結果，並提取圖片URL
      console.log(data);
      return  data.value[0];
    },
    async fetchImagesFromGoogle(keyword) {
      // 這裡需要實現呼叫Google Image Search API的代碼
      // 注意：Google沒有直接的圖片搜尋API，您可能需要使用Custom Search JSON API或其他方式
      // 舉例使用Custom Search JSON API
      const response = await fetch(`https://www.googleapis.com/customsearch/v1?q=${encodeURIComponent(keyword)}&cx=YOUR_CX&searchType=image&key=YOUR_API_KEY`, {
        method: 'GET',
      });
      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }
      const data = await response.json();
      const images = data.items.map(item => item.link);
      return images;
    },
    async handleKeywordClick(keyword) {
      console.log('====handleKeywordClick====');
      try {
        let images = [];
        if (this.currentImageSearchAPI === 'bing') {
          images = await this.fetchImagesFromBing(keyword);
        } else if (this.currentImageSearchAPI === 'google') {
          images = await this.fetchImagesFromGoogle(keyword);
        }

        const firstFiveImages = images.slice(0, 5);
        firstFiveImages.forEach(contentUrl => {
          this.contentItems.push({ type: 'image', value: contentUrl });
          this.contentItems.push({ type: 'text', value: '\n' });
        });

      } catch (error) {
        console.error("Failed to fetch images:", error);
      }
    },
    async handleSubmit() {
      if (!this.inputText.trim()) return;
      //送出問題就開始靜音
      this.stopVoice();
      this.isSubmitting = true; // 開始處理時禁用按鈕並顯示等待文案
      this.submitButtonText = '等待回應中...';
      
      try {
        const response_origin_data = await this.fetchChatGPTResponse(this.inputText);
        const answer = response_origin_data.choices[0].text.trim()
        this.processResponse(this.inputText.trim(),answer,response_origin_data,true);

        // 在這裡調用fetchContenctKeyword
        const keywordResponse = await this.fetchContenctKeyword(answer);
        console.log('=====update before');
        
        // 假設keywordResponse返回的keywords是一個包含關鍵字的數組
        const keywords = keywordResponse.choices[0].text.trim().split('/'); // 根據實際格式調整
        
        this.filteredKeywords = keywords
            // 過濾掉開頭為數字加點的行
            .filter(line => !/^\d+\.\s+/.test(line))
            // 過濾掉空行
            .filter(line => line.trim() !== '')
            // 新增：過濾掉包含換行符的行
            .filter(line => !/[\r\n]+/.test(line))
            // 新增：過濾掉包含特定符號的行
            .filter(line => !/[。,，;；:：!！?？]/.test(line))
            // 使用map和replace移除每行中的換行符和特定符號
            .map(line => line.replace(/[\r\n。,，;；:：!！?？]+/g, '')); 
       
        
      } catch (error) {
        console.error("Failed to fetch ChatGPT response:", error);
      }
    },
    async fetchContenctKeyword(text) {
      const baseUrl = process.env.VUE_APP_API_BASE_URL; // 獲取環境變量中的API基礎URL
      const response = await fetch(`${baseUrl}/api/chatgpt/fetch-content-keyword`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          text: text,
          key: this.apikey
        })
      });

      if (!response.ok) {
        throw new Error('Failed to fetch ChatGPT response');
      }

      const data = await response.json();
      this.currentChatKeywords = data.choices[0].text.trim();
      return data;
    },
    async fetchChatGPTResponse(text) {
      const baseUrl = process.env.VUE_APP_API_BASE_URL; // 獲取環境變量中的API基礎URL
      const response = await fetch(`${baseUrl}/api/master`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          text: text,
          key: this.apikey
        })
      });

      if (!response.ok) {
        throw new Error('Failed to fetch ChatGPT response');
      }

      const data = await response.json();
      return data;
    },
    async checkUser(){
      const baseUrl = process.env.VUE_APP_API_BASE_URL; // 獲取環境變量中的API基礎URL
      const response = await fetch(`${baseUrl}/api/user/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          displayName: this.user.displayName, // 或其他GPT-3模型
          email: this.user.email,
          photoURL:this.user.photoURL,
          accountId: this.user.uid, // 可根據需要調整
          // 其他可選參數
        })
      });

      if (!response.ok) {
        throw new Error('call user api response');
      }

      //const data = await response.json();
    },
    async updateChatlog(obj){
      const baseUrl = process.env.VUE_APP_API_BASE_URL; // 獲取環境變量中的API基礎URL
      const response = await fetch(`${baseUrl}/api/update`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(obj)
      });

      if (!response.ok) {
        throw new Error('Failed to fetch ChatGPT response');
      }

      //const data = await response.json();
      console.log('====resp update');
    },
    async saveHistory(question,answer,account,filename,completion_tokens,prompt_tokens,total_tokens) {
      const baseUrl = process.env.VUE_APP_API_BASE_URL; // 獲取環境變量中的API基礎URL
      const now = new Date();
      const datetimeStr = now.getFullYear() + '-' + 
                    ('0' + (now.getMonth() + 1)).slice(-2) + '-' + 
                    ('0' + now.getDate()).slice(-2) + ' ' + 
                    ('0' + now.getHours()).slice(-2) + ':' + 
                    ('0' + now.getMinutes()).slice(-2) + ':' + 
                    ('0' + now.getSeconds()).slice(-2);

      const voice_text = this.selectedName.toLowerCase();

      const response = await fetch(`${baseUrl}/api/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          question: question, // 或其他GPT-3模型
          answer: answer,
          askedAt:datetimeStr,
          accountId: account, // 可根據需要調整
          audioFilename:filename,
          completion_tokens:completion_tokens,
          prompt_tokens:prompt_tokens,
          total_tokens:total_tokens,
          voice:voice_text,
          // 其他可選參數
        })
      });

      if (!response.ok) {
        throw new Error('Failed to fetch ChatGPT response');
      }

      const data = await response.json();
      //把 chatlog 設定到全域變數
      this.currentChatObj = data;

      //把 keyword 更新到 server 
      this.currentChatObj['keyword'] = this.currentChatKeywords;
      const resp_update = await this.updateChatlog(this.currentChatObj);
      console.log(resp_update);
    },
    async textToSpeech(question,origin_data) {
      this.submitButtonText = '準備語音內容中...';
      const baseUrl = process.env.VUE_APP_API_BASE_URL; // 獲取環境變量中的API基礎URL
      // 獲取當前時間的Timestamp
      const timestamp = Date.now();

      // 將Timestamp轉換成字串
      const timestampStr = timestamp.toString();
      const voice_text = this.selectedName.toLowerCase();

      try {
        const response = await fetch(`${baseUrl}/api/text-to-speech`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ text: origin_data.choices[0].message.content.trim(), time:timestampStr,key:this.apikey, voice:voice_text})
        });
        console.log(response);
        if (response.ok) {
          const data = await response.json(); // 解析JSON格式的回應體
            this.showAudio = true; // 收到響應後顯示audio元素
                    // 回應結果後按鈕恢復正常
            this.isSubmitting = false;
            this.submitButtonText = '';
            // 使用服務器回傳的audioUrl
            const audioUrl = data.audioUrl;
            this.$refs.audioPlayer.src = `/answer_audio/${audioUrl}`; // 設置audio元素的src為audioUrl
            this.$refs.audioPlayer.play().then(() => {
              // 媒體播放開始後的操作
            }).catch(error => {
              console.error("textToSpeech 播放失敗", error);
            });

            //save history
                  
            const user = localStorage.getItem('user');
            if (user) {
              this.user = JSON.parse(user);
              // 進行其他需要的操作，例如隱藏登入框等
              await this.saveHistory(question,origin_data.choices[0].text.trim(),this.user.uid,audioUrl,origin_data.usage.completion_tokens,origin_data.usage.prompt_tokens,origin_data.usage.total_tokens);
          }
            // 自動播放並設置循環播放視頻 (播放人講話)
            const videoPlayer = this.$refs.videoPlayer;
            videoPlayer.loop = true; // 設置循環播放
            videoPlayer.play().then(() => {
              // 媒體播放開始後的操作
            }).catch(error => {
              console.error("video 播放失敗", error);
            });
            //videoPlayer.play(); // 開始播放

            // 添加ended事件監聽器
            this.$refs.audioPlayer.onended = () => {
              this.showAudio = true; // 收到響應後顯示audio元素
              console.log('音頻播放完畢，停止視頻播放');
              this.$refs.videoPlayer.pause(); // 停止視頻播放
              this.$refs.videoPlayer.currentTime = 0; // 將視頻播放進度重置為開始
            };

          } else {
            console.log('轉換失敗');
            this.isSubmitting = false;
            this.submitButtonText = '';
        }
      } catch (error) {
        console.log('API呼叫錯誤', error);
        this.isSubmitting = false;
        this.submitButtonText = '';
      }
  },
  processResponse(question,answer,origin_data,isCreateNewVoice) {
      // 處理ChatGPT回覆的結果...
      this.displayText = ''; // 清空先前的顯示文字
      //this.displayTextByChar(answer); // 開始逐字顯示回應內容
      //this.fullText = answer; // 將API返回的結果設置為要逐字顯示的文本
      //this.currentCharIndex = 0; // 重置字符索引
      //this.typeText(); // 開始逐字顯示文本
      this.contentItems = []; //清除資料
      this.contentItems.push({ type: 'text', value: '\n' });
      this.contentItems.push({ type: 'text', value: question, isHeader: true });
      this.contentItems.push({ type: 'text', value: '\n' });
      this.contentItems.push({ type: 'text', value: answer });
      this.contentItems.push({ type: 'text', value: '\n' });

      //this.scrollToBottom(); // 調用滾動到底部的方法
      if(isCreateNewVoice==true){
        this.textToSpeech(question,origin_data);
      }
      // }else{
      //   this.isSubmitting = false;
      //   this.submitButtonText = '';
      //   this.saveHistory(question,origin_data.choices[0].message.content.trim(),this.user.uid,"",origin_data.usage.completion_tokens,origin_data.usage.prompt_tokens,origin_data.usage.total_tokens);
      // }
    },
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.calculateTextBlockMaxHeight);
  }
};
</script>